@tailwind base;
@tailwind components;
@tailwind utilities;

.wloader {
  width: 28px;
  height: 28px;
  border: 2px solid #E21B70;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  }

@keyframes rotation {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
} 


.text-shadow-stroke {
  color: white; /* Text fill color */
  text-shadow: 
    2px 2px 0 black,   /* Increase the offset for a wider effect */
    -2px 2px 0 black,
    2px -2px 0 black,
    -2px -2px 0 black;
}

